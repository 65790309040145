import React, { useState, useEffect } from "react";
import { Link, Outlet, useNavigate, Navigate } from "react-router-dom";
import { userAuthToken, userName } from "../atoms/authToken";
import { useRecoilValue, useResetRecoilState } from "recoil";
import logo from "../assets/image.png";
import "../style.css";
import {
  MdReorder,
  MdCircle,
  MdGridView,
  MdArrowDropDown,
  MdRefresh,
  MdViewList,
  MdDevices,
  MdInfo,
  MdPeople,
} from "react-icons/md";
import { apiRoute } from "../Globals";
import axios from "axios";
import _ from "lodash";

export default function Dashboard() {
  const authToken = useRecoilValue(userAuthToken);
  const [sideBar, setSideBar] = useState("");
  const [myData, setMyData] = useState([]);
  const [notFound, setNotFound] = useState(true);
  const username = useRecoilValue(userName);
  const navigate = useNavigate();
  const resetAuthToken = useResetRecoilState(userAuthToken);
  const resetUsername = useResetRecoilState(userName);
  const [pollingInterval, setPollingInterval] = useState(3000); // Initial polling interval (5 seconds)

  const isAdmin = authToken === 26;

  useEffect(() => {
    if (!authToken) {
      navigate("/");
    }
  }, []);
  // if (!authToken) {
  //     return <Navigate to="/" />;
  // }

  const toggle = () => {
    if (sideBar === "") {
      setSideBar("toggle-sidebar");
    } else {
      setSideBar("");
    }
  };

  // //fetch all device details
  // useEffect(() => {
  //   try {
  //     axios
  //       .get(`${apiRoute}/fireplusdb-userid/${authToken}`)
  //       .then((Response) => {
  //         console.log(Response);
  //         const status = Response.status;
  //         if (status === 200) {
  //           setMyData(Response.data.Result);
  //           setNotFound(false);
  //         } else if (status === 404) {
  //           setNotFound(true);
  //           console.log(Response.data.Result); // Log the error message
  //         }
  //       })
  //       .catch((error) => {
  //         console.error("Error:", error); // Log any other errors
  //       });
  //   } catch (error) {
  //     console.error("Error:", error);
  //   }
  // }, [authToken]);

  // // Function to fetch data
  //   const fetchData = () => {
  //     try {
  //       axios
  //         .get(`${apiRoute}/fireplusdb-userid/${authToken}`)
  //         .then((response) => {
  //           console.log(response);
  //           const status = response.status;
  //           if (status === 200) {
  //             setMyData(response.data.Result);
  //             setNotFound(false);
  //           } else if (status === 404) {
  //             setNotFound(true);
  //             console.log(response.data.Result); // Log the error message
  //           }
  //         })
  //         .catch((error) => {
  //           console.error("Error:", error); // Log any other errors
  //         });
  //         // setPollingInterval(3000);
  //     } catch (error) {
  //       console.error("Error:", error);
  //     }
  //   };

  // // Function to handle refresh button click
  // const handleRefreshClick = () => {
  //   fetchData();
  // };
  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     fetchData();
  //   }, pollingInterval);

  //   // Clear interval on component unmount
  //   return () => clearInterval(interval);
  // }, [pollingInterval]);

  // setInterval(() => {
  //   fetchData();
  // }, 2000);
  const fetchData = async () => {
    try {
      // await axios.get(`${apiRoute}/firebase/data`).then(
      await axios
        .get(`${apiRoute}/fireplusdb-userid/${authToken}`)
        .then((response) => {
          const status = response.status;
          console.log(response);
          if (status === 200) {
            setMyData(response.data.Result);
            setNotFound(false);
            // console.log(response)
          } else if (status === 404) {
            setNotFound(true);
            console.log(response.data.Result); // Log the error message
          }
        });
      // );
    } catch (error) {
      console.error("Error:", error); // Log any other errors
    }
  };
  const throttledFetchData = _.throttle(fetchData, 3000); // Throttle the fetchData function with a 2-second interval

  const handleRefreshClick = () => {
    throttledFetchData(); // Trigger the throttled function on click
  };

  useEffect(() => {
    throttledFetchData(); // Initial call to start fetching data

    // Clean up the throttled function when the component unmounts
    return () => {
      throttledFetchData.cancel(); // Cancel any pending throttled function calls
    };
  }, []);
  useEffect(() => {
    const intervalId = setInterval(() => {
      throttledFetchData(); // Execute the throttled function at intervals
    }, 5000); // Call the throttled function every 5 seconds

    // Initial fetch
    throttledFetchData();

    // Clean up the interval when the component unmounts
    return () => clearInterval(intervalId);
  }, []);

  // sign out functionality
  const handleSignOut = () => {
    resetAuthToken();
    resetUsername();

    navigate("/");
  };

  return (
    <div className={sideBar}>
      <header
        id="header"
        className="header fixed-top d-flex align-items-center"
      >
        <div className="d-flex align-items-center justify-content-between">
          <Link
            to={"/dashboard"}
            style={{ width: "280px" }}
            className=" d-flex align-items-end"
          >
            <img src={logo} alt="" className="img-fluid " width={100} />
            <span class="d-none d-lg-block text-secondary fs-3 fw-bold">
              IOT
            </span>
          </Link>
          <MdReorder
            className="bi bi-list toggle-sidebar-btn"
            onClick={toggle}
          />
          <i></i>
        </div>

        <nav className="header-nav ms-auto">
          <ul className="d-flex align-items-center">
            {/* <li className="nav-item d-block d-lg-none">
                            <a className="nav-link nav-icon search-bar-toggle " href="/">
                                <i className="bi bi-search"></i>
                            </a>
                        </li> */}

            <li className="nav-item dropdown pe-3">
              <a
                className="nav-link nav-profile d-flex align-items-center pe-0"
                href="/"
                data-bs-toggle="dropdown"
              >
                {/* <img src="assets/img/profile-img.jpg" alt="Profile" className="rounded-circle" /> */}
                <span className="d-none d-md-block dropdown-toggle ps-2">
                  {username}
                </span>
              </a>

              <ul className="dropdown-menu dropdown-menu-end dropdown-menu-arrow profile">
                <li className="dropdown-header">
                  <h6>{username}</h6>
                  {/* <span>Web Designer</span> */}
                </li>

                <li>
                  <hr className="dropdown-divider" />
                </li>

                <li>
                  <button
                    className="dropdown-item d-flex align-items-center"
                    onClick={handleSignOut}
                  >
                    <i className="bi bi-box-arrow-right"></i>
                    <span>Sign Out</span>
                  </button>
                </li>
              </ul>
            </li>
          </ul>
        </nav>
      </header>

      <aside id="sidebar" className="sidebar">
        <ul className="sidebar-nav" id="sidebar-nav">
          <li className="nav-item">
            <Link className="nav-link collapsed" to="/dashboard">
              {/* <i className="bi bi-grid"></i> */}
              <MdGridView size={"20px"} />
              <span>&nbsp;Dashboard</span>
            </Link>
          </li>

          <li className="nav-item">
            <div className="d-flex">
              <a
                className="nav-link collapsed col-10"
                data-bs-target="#components-nav"
                data-bs-toggle="collapse"
                href="/"
              >
                <MdViewList size={"20px"} />
                <span>&nbsp;All UPS&nbsp;</span>

                <MdArrowDropDown size={"20px"} />
              </a>
              <button className="btn " onClick={handleRefreshClick}>
                <MdRefresh size={"20px"} />
              </button>
            </div>

            <ul
              id="components-nav"
              className="nav-content collapse "
              data-bs-parent="#sidebar-nav"
            >
              {myData.map((device, index) => (
                <li className="nav-item" key={index}>
                  <a
                    href={`/dashboard/allpins/${device.token}/${device.user_id}`}
                    onClick={(e) => {
                      e.preventDefault(); 
                      localStorage.setItem('device', JSON.stringify(device)); 
                      window.location.href = `/dashboard/allpins/${device.token}/${device.user_id}`; 
                    }}
                  >
                    <MdDevices size={"20px"} />
                    &nbsp; {device.name}
                    &nbsp;&nbsp;
                    <MdCircle
                      size="25px"
                      style={{
                        color: device.value === "0.00" ? "gray" : "red",
                        marginRight: "20px",
                      }}
                    />
                    <MdCircle
                      size="25px"
                      style={{
                        color:
                          device.D18 === "1" ||
                            device.D19 === "1" ||
                            device.D23 === "1" ||
                            device.D33 === "1" ||
                            device.D18 === "1.00" ||
                            device.D19 === "1.00" ||
                            device.D23 === "1.00" ||
                            device.D33 === "1.00"
                            ? "orange"
                            : "gray",
                      }}
                    />
                  </a>
                </li>
              ))}
            </ul>
          </li>
          {authToken === 26 && (
            <>
              <li className="nav-item">
                <Link
                  className="nav-link collapsed"
                  to="/dashboard/devicedetails"
                >
                  <MdInfo size={"20px"} />
                  <span>&nbsp;UPS Details</span>
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className="nav-link collapsed"
                  data-bs-target="#forms-nav"
                  to="/dashboard/alltemplate"
                >
                  <MdPeople size={"20px"} />
                  <span>&nbsp;All Users</span>
                </Link>
              </li>
            </>
          )}
        </ul>
      </aside>
      <Outlet />
      <footer id="footer" className="footer">
        <div>
          <div className="copyright">
            &copy; Copyright{" "}
            <strong>
              <span>Growmore</span>
            </strong>
            . All Rights Reserved
          </div>
          <div className="credits">
            {" "}
            Designed by <a href="https://growmoretech.in/">Growmoretech</a>
          </div>
        </div>
      </footer>

      <a
        href="/"
        className="back-to-top d-flex align-items-center justify-content-center"
      >
        <i className="bi bi-arrow-up-short"></i>
      </a>
    </div>
  );
}
