import React from "react";
import axios from "axios";
import { useEffect, useState, useRef } from "react";
import { Link, useParams } from "react-router-dom";
import { apiRoute } from "../Globals";
import {
  LineChart,
  Line,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
} from "recharts";
import { userAuthToken } from "../atoms/authToken";
import { useRecoilValue } from "recoil";
import { useDownloadExcel } from "react-export-table-to-excel";
import DataTable from "react-data-table-component"; // Import DataTable
import * as XLSX from 'xlsx';

export default function PinLog() {
  const { token, pinid, pintype, user_id } = useParams();
  const [loading, setLoading] = useState(true);
  const [pinLog, setPinLog] = useState([]);
  const userToken = useRecoilValue(userAuthToken);

  const [deviceName, setDeviceName] = useState();
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [filterError, setFilterError] = useState(null);
  const tableref = useRef(null);
  const tableref2 = useRef(null);

  const { onDownload } = useDownloadExcel({
    currentTableRef: tableref.current,
    filename: "logData",
    sheet: "logData",
  });

  const { onDownload2 } = useDownloadExcel({
    currentTableRef: tableref2.current,
    filename: "logData",
    sheet: "logData",
  });

  const fetchPinLog = () => {
    setLoading(true);
    setFromDate("");
    setToDate("");
    setLoading(true);
    setFilterError(null);
    try {
      axios
        .get(`${apiRoute}/fetch-pin-log-by-date`, {
          params: {
            token: token,
            pin_id: pinid,
            from: fromDate,
            to: toDate,
          },
        })
        .then((Response) => {
          setLoading(false);
          const status = Response.status;
          if (status === 200) {
            setPinLog(Response.data.data);
            setFilterError(null);
          } else if (status === 404) {
            console.log(Response.data.Result);
          }
        })
        .catch((error) => {
          setLoading(false);
          setFilterError("Invalid Request");
        });
    } catch (error) {
      setLoading(false);
      setFilterError("Invalid Request");
    }
  };

  useEffect(() => {
    fetchAllPinLog();
  }, []);

  const fetchAllPinLog = () => {
    try {
      axios
        .get(`${apiRoute}/database-firebase/log/data`, {
          params: {
            token: token,
            pin_id: pinid,
          },
        })
        .then((Response) => {
          setLoading(false);
          const status = Response.status;
          if (status === 200) {
            const data = Response.data.Result;
            setDeviceName(Response.data.Result[0].name);
            if (data[0].pin_name === "D34") {
              const filteredData = data.filter(
                (item, index) => index % 60 === 0
              );
              setPinLog(filteredData);
            } else {
              setPinLog(data);
            }
          } else if (status === 404) {
            console.log(Response.data.Result);
          }
        })
        .catch((error) => {
          setLoading(false);
        });
    } catch (error) {
      setLoading(false);
    }
  };

  var data = pinLog.map((item) => {
    var timestamp = new Date(item.created).toLocaleString();
    var yValue = parseFloat(item.latest_value);
    return { timestamp: timestamp, value: yValue };
  });

  // DataTable columns configuration
  const columns = [
    {
      name: "#",
      selector: (row, index) => index + 1,
      sortable: true,
    },
    userToken === 26 && {
      name: "Name",
      selector: (row) => row.pin_name,
      sortable: true,
    },
    {
      name: "Title",
      selector: (row) => row.pin_title,
      sortable: true,
    },
    {
      name: "Latest Value",
      selector: (row) => (row.latest_value === "0.00" || row.latest_value === "0" ? "Off" : row.latest_value === "1.00" || row.latest_value === "1" ? "On" : row.latest_value),
      sortable: true,
    },
    {
      name: "Timestamp",
      selector: (row) => {
        const updatedTime = new Date(row.updated);
        updatedTime.setHours(updatedTime.getHours() + 11);
        updatedTime.setMinutes(updatedTime.getMinutes() + 0);
        return updatedTime.toISOString().replace("T", " ").replace(".000Z", "");
      },
      sortable: true,
    },
  ].filter(Boolean);
  const exportToExcel = (data, filename) => {
    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    XLSX.writeFile(workbook, `${filename}.xlsx`);
  };
  
  // Call this function in the appropriate place, e.g., in the "Export Excel" button click handler:
  const handleExport = () => {
    if (pinLog && pinLog.length > 0) {
      exportToExcel(pinLog, "logData");
    } else {
      alert("No data available for export!");
    }
  };
    // Custom actions for the DataTable
    const customActions = (
      <button
        className="btn btn-primary col-12"
        onClick={() => exportToExcel(pinLog, "PinLogData")}
      >
        Export to Excel
      </button>
    );
  return (
    <div>
      <main id="main" className="main">
        <div className="pagetitle">
          <h1>All Pins</h1>
          <nav>
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to={"/dashboard"}>Home</Link>
              </li>
              <li className="breadcrumb-item">
                <Link to={`/dashboard/allpins/${token}/${user_id}`}>All Pins</Link>
              </li>
              <li className="breadcrumb-item active">
                <Link to={`/dashboard/allpins/pinLog/${token}/${pinid}/${pintype}/${user_id}`}>Pin Log</Link>
              </li>
            </ol>
          </nav>
        </div>

        <section className="section dashboard" style={{ height: "87%" }}>
          <div className="card">
            <div className="card-body pt-5">
              <h5>Device Serial Number is {deviceName}</h5>
              <div className="w-100 d-flex justify-content-center my-3">
                <div
                  className="w-100 border border-2 p-3 m-3 mx-auto"
                  style={{ display: "inline-block" }}
                >
                  <h6>Filter the records based on date.</h6>
                  <div className="d-flex my-3">
                    <div>
                      <span>From:</span>
                      <input
                        type="date"
                        value={fromDate}
                        className="mx-3 p-2"
                        style={{
                          borderRadius: "5px",
                          border: "1px solid #b5b5b5",
                        }}
                        onChange={(e) => setFromDate(e.target.value)}
                      />
                    </div>
                    <div>
                      <span>To:</span>
                      <input
                        type="date"
                        value={toDate}
                        className="mx-3 p-2"
                        style={{
                          borderRadius: "5px",
                          border: "1px solid #b5b5b5",
                        }}
                        onChange={(e) => setToDate(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="w-100 d-flex justify-content-start">
                    <button className="btn btn-primary" onClick={fetchPinLog}>
                      filter Data
                    </button>
                    <button
                      className="mx-3 btn btn-danger"
                      onClick={fetchAllPinLog}
                    >
                      Clear Filter
                    </button>
                  </div>
                </div>
              </div>
              {pintype === "Reading" ? (
                <>
                  <ul className="nav nav-tabs" id="myTab" role="tablist">
                    <li className="nav-item flex-fill" role="presentation">
                      <button
                        className="nav-link w-100 active"
                        id="home-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#home"
                        type="button"
                        role="tab"
                        aria-controls="home"
                        aria-selected="true"
                      >
                        Graph
                      </button>
                    </li>
                    <li className="nav-item flex-fill" role="presentation">
                      <button
                        className="nav-link w-100"
                        id="profile-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#profile"
                        type="button"
                        role="tab"
                        aria-controls="profile"
                        aria-selected="false"
                      >
                        Table
                      </button>
                    </li>
                  </ul>
                  <div className="tab-content pt-2" id="myTabContent">
                    <div
                      className="tab-pane fade show active"
                      id="home"
                      role="tabpanel"
                      aria-labelledby="home-tab"
                    >
                      <div
                        className="d-flex justify-content-center"
                        style={{ maxWidth: "1000px", overflowX: "auto" }}
                      >
                        <div style={{ minWidth: "1000px" }}>
                          <LineChart
                            width={data.length * 70}
                            height={300}
                            data={data}
                            margin={{ top: 5, right: 20, bottom: 5, left: 0 }}
                          >
                            <Line type="monotone" dataKey="value" stroke="#8884d8" />
                            <CartesianGrid stroke="#ccc" strokeDasharray="5 10" />
                            <XAxis dataKey="timestamp" />
                            <YAxis />
                            <Tooltip />
                          </LineChart>
                        </div>
                      </div>
                    </div>
                    <div
                      className="tab-pane fade"
                      id="profile"
                      role="tabpanel"
                      aria-labelledby="profile-tab"
                    >
                      <button class="btn btn-primary col-12" onClick={handleExport}>
                        Export Excel
                      </button>
                      <DataTable
                        columns={columns}
                        data={pinLog}
                        pagination
                        highlightOnHover
                        responsive
                        // actions={customActions} 

                      />
                    </div>
                  </div>
                </>
              ) : (
                <div className="row justify-content-around">
                  <button class="btn btn-primary col-12" onClick={handleExport}>
                    Export Excel
                  </button>
                  {filterError && <p className="text-center my-3 fw-bold">{filterError}</p>}
                  {loading ? (
                    <div className="d-flex justify-content-center">
                      <button className="btn btn-primary" type="button">
                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        &nbsp; Loading ...
                      </button>
                    </div>
                  ) : pinLog && pinLog.length === 0 ? (
                    <p>No pins available.</p>
                  ) : (
                    <DataTable
                      columns={columns}
                      data={pinLog}
                      pagination
                      highlightOnHover
                      responsive
                      // actions={customActions} 
                    />
                  )}
                </div>
              )}
            </div>
          </div>
        </section>
      </main>
    </div>
  );
}
