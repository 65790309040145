/* eslint-disable no-unused-vars */
import axios from 'axios'
import React, { useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { userAuthToken } from '../atoms/authToken';
import { useRecoilValue } from 'recoil';
import { apiRoute } from '../Globals';

export default function AllTemplate() {

    const authToken = useRecoilValue(userAuthToken);
    const navigate = useNavigate();
    const [myData, setMyData] = useState([]);
    const [isError, setIsError] = useState("");
    const [Loading, setLoading] = useState(true);
    const [notFound, setNotFound] = useState(true)
    useEffect(() => {
        if (authToken !== 26) {
            navigate("/dashboard")
        }
    })


    //fetch all device details
    useEffect(() => {
        try {
            axios.get(`${apiRoute}/get-all-users`)
                .then((Response) => {
                    console.log(Response);
                    const status = Response.status;
                    if (status === 200) {
                        setMyData(Response.data.data);
                        setNotFound(false);
                    } else if (status === 404) {
                        setNotFound(true);
                        console.log(Response.data.data); // Log the error message
                    }
                    setLoading(false);
                })
                .catch((error) => {
                    setLoading(false);

                    console.error("Error:", error); // Log any other errors
                });
        } catch (error) {
            setLoading(false);

            console.error("Error:", error);
        }
    }, [authToken]);

    return (
        <div>
            <main id="main" className="main">
                <div className="pagetitle">
                    <h1>All Users</h1>
                    <nav>
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><Link to="/dashboard">Home</Link></li>
                            <li className="breadcrumb-item active"><Link to="/dashboard/alltemplate">All Users</Link></li>
                        </ol>
                    </nav>
                </div>
                <section className="section dashboard" style={{ height: "87%" }}>
                    <div className="d-grid gap-2 mt-3">

                        <div className="row">

                            <div className="card">
                                <div className="card-body">
                                    <h5 className="card-title">All Users</h5>

                                    <div className="d-grid gap-2 m-3 my-4">
                                        <Link to='/dashboard/register'
                                            type="button"
                                            className="btn btn-primary"
                                        >
                                            Add New User
                                        </Link>
                                    </div>
                                    <table className="table" id='myTable'>
                                        <thead>
                                            <tr>
                                                <th scope="col">#</th>
                                                <th scope="col">UID</th>
                                                <th scope="col">User Name</th>
                                                <th scope="col">User Email</th>
                                                <th scope="col">Change Password</th>
                                                <th scope="col">Created At</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {Loading ? (
                                                <div className='d-block'>
                                                    <div className="spinner-border text-primary my-3" role="status">
                                                        <span className="visually-hidden">Loading...</span>
                                                    </div>
                                                </div>
                                            ) : notFound ? (
                                                <p>No devices available.</p>
                                            ) : (
                                                myData.slice().reverse().map((template, index) => (
                                                    <tr key={template.id}>
                                                        <th scope="row">{index + 1}</th>
                                                        <td>{template.id}</td>
                                                        <td>{template.name}</td>
                                                        <td>{template.email}</td>
                                                        <td className=' d-flex justify-content-center'><Link to={`/dashboard/changepassword/${template.id}/${template.name}`} className='btn btn-primary'>change</Link></td>
                                                        <td>{template.created_at}</td>

                                                    </tr>
                                                )))
                                            }

                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        </div>
    )
}
