import { useState, useEffect } from "react";
import React from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import { apiRoute } from "../Globals";
import { userAuthToken } from "../atoms/authToken";
import { useRecoilValue } from "recoil";
import axios from "axios";

function DeviceDetailsForm() {
  const userToken = useRecoilValue(userAuthToken);
  const { action, id } = useParams();
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    sNo: "",
    site: "",
    date: "",
    batt: "",
    igbt: "",
    card1: "",
    card2: "",
    card3: "",
    card4: "",
    transformer: "",
    field1: "",
    field2: "",
    field3: "",
    field4: "",
    field5: "",
    field6: "",
    field7: "",
    field8: "",
    field9: "",
    field10: "",
  });

  useEffect(() => {
    if (action === "edit") {
      // Fetch data for editing and update the form data
      axios
        .get(`${apiRoute}/particular-device/details`, {
          params: {
            user_id: userToken,
            id: id,
          },
        })
        .then((response) => {
          const data = response.data.pin_type;
          setFormData({
            sNo: data.serial_number,
            site: data.site,
            date: data.date.substr(0, 10),
            batt: data.batt,
            igbt: data.igbt,
            card1: data.card_1,
            card2: data.card_2,
            card3: data.card_3,
            card4: data.card_4,
            transformer: data.transformer,
            field1: data.field_1,
            field2: data.field_2,
            field3: data.field_3,
            field4: data.field_4,
            field5: data.field_5,
            field6: data.field_6,
            field7: data.field_7,
            field8: data.field_8,
            field9: data.field_9,
            field10: data.field_10,
          });
        })
        .catch((error) => {
          console.error("Error fetching data for edit:", error);
        });
    }
  }, [action]);

  //handle change in the form data
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    if (action === "add") {
      console.log("working");
      console.log(apiRoute);
      console.log(userToken);
      axios
        .post(`${apiRoute}/device/create`, {
          user_id: `${userToken}`,
          serial_number: formData.sNo,
          site: formData.site,
          date: formData.date,
          batt: formData.batt,
          igbt: formData.igbt,
          card_1: formData.card1,
          card_2: formData.card2,
          card_3: formData.card3,
          card_4: formData.card4,
          transformer: formData.transformer,
          field_1: formData.field1,
          field_2: formData.field2,
          field_3: formData.field3,
          field_4: formData.field4,
          field_5: formData.field5,
          field_6: formData.field6,
          field_7: formData.field7,
          field_8: formData.field8,
          field_9: formData.field9,
          field_10: formData.field10,
        })
        .then((Response) => {
          console.log(Response);
          navigate("/dashboard/devicedetails");
        })
        .catch((error) => console.log(error));
    } else {
      try {
        axios
          .post(`${apiRoute}/device/update/${userToken}/${id}`, {
            user_id: `${userToken}`,
            serial_number: formData.sNo,
            site: formData.site,
            date: formData.date,
            batt: formData.batt,
            igbt: formData.igbt,
            card_1: formData.card1,
            card_2: formData.card2,
            card_3: formData.card3,
            card_4: formData.card4,
            transformer: formData.transformer,
            field_1: formData.field1,
            field_2: formData.field2,
            field_3: formData.field3,
            field_4: formData.field4,
            field_5: formData.field5,
            field_6: formData.field6,
            field_7: formData.field7,
            field_8: formData.field8,
            field_9: formData.field9,
            field_10: formData.field10,
          })
          .then((Response) => {
            console.log(Response);
            navigate("/dashboard/devicedetails");
          })
          .catch((error) => console.log(error));
      } catch (error) {
        var x = 0;
      }
    }
  };
  return (
    <div>
      <main id="main" className="main">
        <div className="pagetitle">
          <h1>{action === "add" ? "Add New" : "Edit"} UPS Details</h1>
          <nav>
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <li>
                  <Link to={"./"}>Home</Link>
                </li>
              </li>
              <li className="breadcrumb-item">
                <Link to={"/dashboard/devicedetails"}>UPS Details</Link>
              </li>
              <li className="breadcrumb-item active">
                <Link to={`/dashboard/devicedetailsform/${action}/${id}`}>
                  UPS Details Form
                </Link>
              </li>
            </ol>
          </nav>
        </div>
        <section className="section " style={{ height: "87%" }}>
          <form onSubmit={handleSubmit}>
            <div className="row mx-auto">
              <div className="form-floating col-5 mx-auto  p-0 m-2 mb-3">
                <input
                  type="text"
                  className="form-control"
                  required
                  id="floatingInput"
                  placeholder="Serial Number"
                  name="sNo"
                  value={formData.sNo}
                  onChange={handleChange}
                />
                <label htmlFor="floatingInput">Serial Number</label>
              </div>
              <div className="form-floating col-5 mx-auto  p-0 m-2 mb-3">
                <input
                  type="text"
                  className="form-control"
                  id="floatingtext"
                  placeholder="site"
                  required
                  name="site"
                  value={formData.site}
                  onChange={handleChange}
                />
                <label htmlFor="floatingtext">Site</label>
              </div>
              <div className="form-floating col-5 mx-auto  p-0 m-2 mb-3">
                <input
                  type="date"
                  className="form-control"
                  id="floatingtext1"
                  name="date"
                  value={formData.date}
                  onChange={handleChange}
                />
                <label htmlFor="floatingtext1">Date</label>
              </div>
              <div className="form-floating col-5 mx-auto  p-0 m-2 mb-3">
                <input
                  type="text"
                  className="form-control"
                  id="floatingtext2"
                  placeholder="batt"
                  name="batt"
                  value={formData.batt}
                  onChange={handleChange}
                />
                <label htmlFor="floatingtext2">Battery</label>
              </div>
              <div className="form-floating col-5 mx-auto  p-0 m-2 mb-3">
                <input
                  type="text"
                  className="form-control"
                  id="floatingtext3"
                  placeholder="igbt"
                  name="igbt"
                  value={formData.igbt}
                  onChange={handleChange}
                />
                <label htmlFor="floatingtext3">IGBT</label>
              </div>
              <div className="form-floating col-5 mx-auto  p-0 m-2 mb-3">
                <input
                  type="text"
                  className="form-control"
                  id="floatingtext4"
                  placeholder="card1"
                  name="card1"
                  value={formData.card1}
                  onChange={handleChange}
                />
                <label htmlFor="floatingtext4">Charger Card</label>
              </div>
              <div className="form-floating col-5 mx-auto  p-0 m-2 mb-3">
                <input
                  type="text"
                  className="form-control"
                  id="floatingtext5"
                  placeholder="card2"
                  name="card2"
                  value={formData.card2}
                  onChange={handleChange}
                />
                <label htmlFor="floatingtext5">Inverter Card</label>
              </div>
              <div className="form-floating col-5 mx-auto  p-0 m-2 mb-3">
                <input
                  type="text"
                  className="form-control"
                  id="floatingtext6"
                  placeholder="card3"
                  name="card3"
                  value={formData.card3}
                  onChange={handleChange}
                />
                <label htmlFor="floatingtext6">Display Card</label>
              </div>
              <div className="form-floating col-5 mx-auto  p-0 m-2 mb-3">
                <input
                  type="text"
                  className="form-control"
                  id="floatingtext7"
                  placeholder="card4"
                  name="card4"
                  value={formData.card4}
                  onChange={handleChange}
                />
                <label htmlFor="floatingtext7">Filter Card</label>
              </div>
              <div className="form-floating col-5 mx-auto  p-0 m-2 mb-3">
                <input
                  type="text"
                  className="form-control"
                  id="floatingtext8"
                  placeholder="transformer"
                  name="transformer"
                  value={formData.transformer}
                  onChange={handleChange}
                />
                <label htmlFor="floatingtext8">Inverter Transformer</label>
              </div>
              <div className="form-floating col-5 mx-auto  p-0 m-2 mb-3">
                <input
                  type="text"
                  className="form-control"
                  id="floatingtext9"
                  placeholder="field1"
                  name="field1"
                  value={formData.field1}
                  onChange={handleChange}
                />
                <label htmlFor="floatingtext9">Inductor</label>
              </div>
              <div className="form-floating col-5 mx-auto  p-0 m-2 mb-3">
                <input
                  type="text"
                  className="form-control"
                  id="floatingtext10"
                  placeholder="field2"
                  name="field2"
                  value={formData.field2}
                  onChange={handleChange}
                />
                <label htmlFor="floatingtext10">Trigger Transformer</label>
              </div>
              <div className="form-floating col-5 mx-auto  p-0 m-2 mb-3">
                <input
                  type="text"
                  className="form-control"
                  id="floatingtext11"
                  placeholder="field3"
                  name="field3"
                  value={formData.field3}
                  onChange={handleChange}
                />
                <label htmlFor="floatingtext11">Earth Trigger</label>
              </div>
              <div className="form-floating col-5 mx-auto  p-0 m-2 mb-3">
                <input
                  type="text"
                  className="form-control"
                  id="floatingtext12"
                  placeholder="field4"
                  name="field4"
                  value={formData.field4}
                  onChange={handleChange}
                />
                <label htmlFor="floatingtext12">Bridge Module</label>
              </div>
              <div className="form-floating col-5 mx-auto  p-0 m-2 mb-3">
                <input
                  type="text"
                  className="form-control"
                  id="floatingtext13"
                  placeholder="field5"
                  name="field5"
                  value={formData.field5}
                  onChange={handleChange}
                />
                <label htmlFor="floatingtext13">Input MCB</label>
              </div>
              <div className="form-floating col-5 mx-auto  p-0 m-2 mb-3">
                <input
                  type="text"
                  className="form-control"
                  id="floatingtext14"
                  placeholder="field6"
                  name="field6"
                  value={formData.field6}
                  onChange={handleChange}
                />
                <label htmlFor="floatingtext14">Input Filter</label>
              </div>
              <div className="form-floating col-5 mx-auto  p-0 m-2 mb-3">
                <input
                  type="text"
                  className="form-control"
                  id="floatingtext15"
                  placeholder="field7"
                  name="field7"
                  value={formData.field7}
                  onChange={handleChange}
                />
                <label htmlFor="floatingtext15">Fan</label>
              </div>
              <div className="form-floating col-5 mx-auto  p-0 m-2 mb-3">
                <input
                  type="text"
                  className="form-control"
                  id="floatingtext16"
                  placeholder="field8"
                  name="field8"
                  value={formData.field8}
                  onChange={handleChange}
                />
                <label htmlFor="floatingtext16">Fuse</label>
              </div>
              <div className="form-floating col-5 mx-auto  p-0 m-2 mb-3">
                <input
                  type="text"
                  className="form-control"
                  id="floatingtext17"
                  placeholder="IOT"
                  name="field9"
                  value={formData.field9}
                  onChange={handleChange}
                />
                <label htmlFor="floatingtext17">IOT</label>
              </div>
              <div className="form-floating col-5 mx-auto  p-0 m-2 mb-3">
                <input
                  type="text"
                  className="form-control"
                  id="floatingtext18"
                  placeholder="field10"
                  name="field10"
                  value={formData.field10}
                  onChange={handleChange}
                />
                <label htmlFor="floatingtext18">Remarks</label>
              </div>
            </div>
            <button
              type="submit"
              className="btn btn-primary"
              onSubmit={handleSubmit}
            >
              Save
            </button>
          </form>
        </section>
      </main>
    </div>
  );
}

export default DeviceDetailsForm;
